import { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../Layouts/Loader/Loadable";

const Auth = Loadable(lazy(() => import("../Components/SignUp")));
const SignIn = Loadable(lazy(() => import("../Components/SignUp/SignIn")));
const AdminSignIn = Loadable(
  lazy(() => import("../Components/SignUp/AdminSignIn"))
);
const SignUp = Loadable(lazy(() => import("../Components/SignUp/SignUp")));
const ForgotPassword = Loadable(
  lazy(() => import("../Components/SignUp/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../Components/SignUp/ResetPassword"))
);
const MainDashboard = Loadable(
  lazy(() => import("../Components/Main/MainDashboard/DashboardPage"))
);
const AddNewInvestment = Loadable(
  lazy(() =>
    import(
      "../Components/UIForAdmin/body/investmentTabSection/AddNewInvestment"
    )
  )
);
// const AddStrategies = Loadable(lazy(() => import("../Components/UIForAdmin/body/StrategiesSection/AddStrategies")));
const MailLogs = Loadable(
  lazy(() => import("../Components/UIForAdmin/body/MailLogs"))
);
const RebalancePortfolio = Loadable(
  lazy(() =>
    import("../Components/UIForAdmin/body/StrategiesSection/Rebalance")
  )
);
const UserTable = Loadable(
  lazy(() => import("../Components/UIForAdmin/Table/UserTable"))
);
const InvestmentTable = Loadable(
  lazy(() => import("../Components/UIForAdmin/Table/InvestmentTable"))
);
const SettingsSection = Loadable(
  lazy(() => import("../Components/UIForAdmin/body/SettingsSection"))
);
const FileUpload = Loadable(
  lazy(() => import("../Components/UIForAdmin/body/FileUpload"))
);
const DashboardCardPlacement = Loadable(
  lazy(() =>
    import("../Components/UIForClient/CommonComponents/CardPlacements")
  )
);
const RecommendedAssets = Loadable(
  lazy(() => import("../Components/UIForClient/RecommendedAssets"))
);
const PolicyComponent = Loadable(
  lazy(() =>
    import("../Components/UIForClient/PrivacyPolicy/PrivacyPolicy.jsx")
  )
);
const StrategyAdmin = Loadable(
  lazy(() => import("../Components/UIForAdmin/body/Strategy"))
);
const Strategy = Loadable(
  lazy(() => import("../Components/UIForClient/Strategies"))
);


const ThankYou=Loadable(
  lazy(() => import("../Components/UIForClient/RiskQuestionaire/ThankYouPage.jsx"))
);
const RiskQuestionaire = Loadable(
  lazy(() => import("../Components/UIForClient/RiskQuestionaire/index.jsx"))
);
const OfiicialAgreement= Loadable(
  lazy(() => import("../Components/UIForClient/OfiicialAgreement/index.jsx"))
);
// const RiskQuestionaire=Loadable(lazy(()=>import('../Components/UIForClient/RiskProfiling')))
const LandingPage = Loadable(
  lazy(() => import("../Components/UIForClient/Dashboard"))
);
const RiskFreeReport = Loadable(
  lazy(() => import("../Components/UIForClient/Dashboard/RiskReport"))
);
const MainLandingPage = Loadable(
  lazy(() => import("../Components/Main/MainLanding"))
);
const FactorPage = Loadable(
  lazy(() => import("../Components/UIForClient/FactorPage"))
);
const MyStrategy = Loadable(
  lazy(() => import("../Components/UIForClient/MyStrategy"))
);
const StrategyDetails = Loadable(
  lazy(() =>
    import("../Components/UIForAdmin/body/StrategiesSection/StrategyDetails")
  )
);
const MyStrategyDetail = Loadable(
  lazy(() => import("../Components/UIForClient/MyStrategyDetail"))
);
const Broker = Loadable(
  lazy(() => import("../Components/UIForClient/BrokersIntegration"))
);
const UpstoxRedirect = Loadable(
  lazy(() =>
    import("../Components/UIForClient/BrokersIntegration/UpstoxRedirect.jsx")
  )
);
const RebalancingComponent = Loadable(
  lazy(() => import("../Components/UIForClient/Rebalance"))
);
const PaymentModule = Loadable(
  lazy(() => import("../Components/UIForClient/Payment"))
);
const PaymentFailedModule = Loadable(
  lazy(() => import("../Components/UIForClient/Payment/PaymentFailed.jsx"))
);
// const CouponList = Loadable(lazy(() => import('../Components/UIForAdmin/body/CouponSection/CouponsList')));
const PaymentSucess = Loadable(
  lazy(() => import("../Components/UIForClient/Payment/PaymentSuccess.jsx"))
);

const NotFoundPage = Loadable(
  lazy(() => import("../Components/Common/NotFoundPage.jsx"))
);

const ClientMasterTable = Loadable(
  lazy(() => import("../Components/UIForAdmin/Table/ClientMasterTable/index.jsx"))
);
const DistributorMasterTable = Loadable(
  lazy(() => import("../Components/UIForAdmin/Table/DistributorMasterTable/index.jsx"))
);

const DownloadTransactions = Loadable(
  lazy(() => import("../Components/UIForAdmin/Table/DownloadTransactions/index.jsx"))
);
const DistributorLogoUpdate = Loadable(
  lazy(() => import("../Components/UIForAdmin/Table/DistributorLogoUpdate/index.jsx"))
);
const RiskAnalyserUser = Loadable(
  lazy(() => import("../Components/UIForAdmin/Table/RiskAnalyserUser/index.jsx"))
);
const SuitabilityReportDashboard= Loadable(
  lazy(() => import("../Components/UIForAdmin/Table/SuitabilityReportDashboard/index.jsx"))
);

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    exact: true,
    element: <MainLandingPage />,
  },
  {
    path: "/riskscore",
    exact: true,
    element: <RiskFreeReport />,
  },
  {
    path: "/strategy",
    exact: true,
    element: <Strategy type="preLogin" />,
  },
  {
    path: "/thank-you",
    exact: true,
    element: <ThankYou type="preLogin" />,
  },
  {
    path: "/riskquestionaire",
    exact: true,
    element: <RiskQuestionaire type="preLogin" />,
  },
  {
    path: "/official-agreement",
    exact: true,
    element: <OfiicialAgreement type="preLogin" />,
  },
  {
    path: "/:distributor/strategy",
    exact: true,
    element: <Strategy type="preLogin" />,
  },
  {
    path: "/rebalance-portfolio",
    exact: true,
    element: <RebalancingComponent />,
  },
  {
    path: "/payment-success",
    exact: true,
    element: <PaymentSucess />,
  },
  {
    path: "payment-failed",
    exact: true,
    element: <PaymentFailedModule />,
  },
  {
    path: "/privacyPolicy",
    exact: true,
    element: <PolicyComponent privacy={true} terms={false} />,
  },

  {
    path: "/terms",
    exact: true,
    element: <PolicyComponent privacy={false} terms={true} />,
  },
  {
    path: "/auth",
    element: <Auth />,
    children: [
      { path: "signin", element: <SignIn /> },
      { path: "signup", element: <SignUp /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password/:resetToken", element: <ResetPassword /> },
      { path: "/auth", element: <Navigate to="/auth/signin" replace /> },
    ],
  },
  {
    path: "/admins",
    element: <Auth />,
    children: [
      { path: "signin", element: <AdminSignIn /> },
      { path: "signup", element: <SignUp /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password/:resetToken", element: <ResetPassword /> },
      { path: "/admins", element: <Navigate to="/admins" replace /> },
    ],
  },

  {
    path: "/admin",
    exact: true,
    element: <MainDashboard type="admin" />,
    children: [
      { path: "dashboard", element: <h1> Dashboard </h1> },
      { path: "add-new-investment", element: <AddNewInvestment /> },
      { path: "client", element: <UserTable /> },
      { path: "investment", element: <InvestmentTable /> },
      { path: "settings", element: <SettingsSection /> },
      { path: "uploads", element: <FileUpload /> },
      // { path: "coupons", element: <CouponList />},
      { path: "rebalance-portfolio", element: <RebalancePortfolio /> },
      { path: "strategies", element: <StrategyAdmin type="admin" /> },
      { path: "maillogs", element: <MailLogs /> },
      { path: "*", element: <NotFoundPage /> },
      {path:"client-master",element:<ClientMasterTable />},
      {path:"distributor-master",element:<DistributorMasterTable />},
      {path:"download-transaction",element:<DownloadTransactions/>},
      {path:"distributor-logo-update",element:<DistributorLogoUpdate />},
      {path:"portfolio-dashboard",element:<RiskAnalyserUser/>},
      {path:"report-dashboard",element:<SuitabilityReportDashboard/>}
    ],
  },
  {
    path: "/user",
    element: <MainDashboard type="user" />,
    children: [
      { path: "home", element: <DashboardCardPlacement /> },
      { path: "factor", element: <FactorPage /> },
      { path: "recommendedassets", element: <RecommendedAssets /> },
      { path: "riskprofiling" },
      {
        path: "riskanalyser",
        element: <LandingPage showDownloadBtn={false} dashboard={true} />,
      },
      { path: "strategy", element: <Strategy type="user" /> },
      {
        path: "strategy-details",
        element: (
          <StrategyDetails
            strategy={JSON.parse(localStorage.getItem("strategy"))}
            type="user"
            purchased={JSON.parse(localStorage.getItem("purchasedFlag"))}
          />
        ),
      },
      { path: "Mystrategy", element: <MyStrategy /> },
      {
        path: "portfolio",
        element: (
          <div
            style={{
              width: "100%",
              padding: "20px",
              boxSizing: "border-box",
              backgroundColor: " white",
            }}
          >
            <MyStrategyDetail
              strategy={JSON.parse(localStorage.getItem("strategy"))}
              type={"post_payment"}
            />
          </div>
        ),
      },
      {
        path: "upstox-oauth-callback",
        element: (
          <Broker
            strategy={JSON.parse(localStorage.getItem("strategy"))}
            type="upstox"
          />
        ),
      },
      {
        path: "masterTrust-oauth-callback",
        element: (
          <Broker
            strategy={JSON.parse(localStorage.getItem("strategy"))}
            type="mastertrust"
          />
        ),
      },
      {
        path: "angelOne-callback",
        element: (
          <Broker
            strategy={JSON.parse(localStorage.getItem("strategy"))}
            type="angelone"
          />
        ),
      },
      {
        path: "motilal-callback",
        element: (
          <Broker
            strategy={JSON.parse(localStorage.getItem("strategy"))}
            type="motilal"
          />
        ),
      },
      {
        path: "fyers-oauth-callback",
        element: (
          <Broker
            strategy={JSON.parse(localStorage.getItem("strategy"))}
            type="fyers"
          />
        ),
      },

      {
        path: "zerodha-oauth-callback",
        element: (
          <Broker
            strategy={JSON.parse(localStorage.getItem("strategy"))}
            type="zerodha"
          />
        ),
      },

      {
        path: "payment",
        element: (
          <PaymentModule
            strategy={JSON.parse(localStorage.getItem("strategy"))}
          />
        ),
      },
      { path: "upstox-redirect", element: <UpstoxRedirect /> },
      { path: "*", element: <NotFoundPage /> },

      // {path: "privacyPolicy",element:<PolicyComponent/>}
    ],
  },
  { path: "*", element: <Navigate to="/" replace />  },
  {
    path: "/:distributor/auth",
    element: <Auth />,
    children: [
      { path: "signin", element: <SignIn /> },
      { path: "signup", element: <SignUp /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password/:resetToken", element: <ResetPassword /> },
    ],
  },
  {
    path: "/:distributor/admins",
    element: <Auth />,
    children: [
      { path: "signin", element: <AdminSignIn /> },
      { path: "signup", element: <SignUp /> },
      { path: "forgot-password", element: <ForgotPassword /> },
      { path: "reset-password/:resetToken", element: <ResetPassword /> },
    ],
  },
];

export default ThemeRoutes;
